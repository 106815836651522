import { includes, get, uniqBy, noop } from 'lodash-es'
import { Breadcrumb } from '../logger'
import {
  isDatasetReady,
  isReadOnly,
  isEditable,
  selectNextDynamicPageUrl,
  selectPreviousDynamicPageUrl,
} from '../dataset-controller/rootReducer'
import isInputComponent from '../helpers/isInputComponent'
import {
  canLoadMoreItems,
  hasNextItem,
  hasPreviousItem,
  hasNextPage,
  hasPreviousPage,
} from '../helpers/paginationUtils'
import {
  DROPDOWN_OPTIONS_ROLE,
  FILTER_INPUT_ROLE,
  BUTTON_ROLE,
  ICON_BUTTON_ROLE,
  STYLABLE_BUTTON_ROLE,
  SELECTION_TAGS_OPTIONS_ROLE,
  FILTER_INPUT_ROLE_V2,
} from '@wix/wix-data-client-common/src/connection-config/roles'

const LINKABLE_DISABLEABLE_COMPONENTS = [
  BUTTON_ROLE,
  ICON_BUTTON_ROLE,
  STYLABLE_BUTTON_ROLE,
]

const undisabableRoles = [
  DROPDOWN_OPTIONS_ROLE,
  FILTER_INPUT_ROLE,
  FILTER_INPUT_ROLE_V2,
  SELECTION_TAGS_OPTIONS_ROLE,
]

const getDisableableInputCacs = cacs =>
  cacs.filter(
    ({ role, component }) =>
      !undisabableRoles.includes(role) && isInputComponent(component),
  )

const getDisableableLinkedCacs = (cacs, disableableActions) =>
  cacs
    .filter(({ role }) => includes(LINKABLE_DISABLEABLE_COMPONENTS, role))
    .filter(({ connectionConfig }) =>
      includes(
        disableableActions,
        get(connectionConfig, 'events.onClick.action'),
      ),
    )

const getDisableableComponents = (cacs, disableableActions) => {
  const uniqueDisableableComponentContexts = uniqBy(
    cacs.filter(({ component }) => component.enabled),
    ({ compId }) => compId,
  )

  return {
    inputCacs: getDisableableInputCacs(uniqueDisableableComponentContexts),
    linkedCacs: getDisableableLinkedCacs(
      uniqueDisableableComponentContexts,
      disableableActions,
    ),
  }
}

const updateComponentEnabledState = (
  comp,
  compId,
  shouldBeEnabled,
  logger,
  datasetId,
) => {
  if (comp.enabled !== shouldBeEnabled) {
    shouldBeEnabled ? comp.enable() : comp.disable()
    logger.log(
      new Breadcrumb({
        category: 'components',
        message: `${compId} changed to ${
          shouldBeEnabled ? 'enabled' : 'disabled'
        } (dataset: ${datasetId})`,
      }),
    )
  }
}

const getSyncComponentsWithStateSubscriber =
  ({
    getState,
    inputCacs,
    linkedCacs,
    datasetId,
    logger,
    shouldEnableLinkedComponent,
  }) =>
  () => {
    const state = getState()
    if (!isDatasetReady(state)) {
      return
    }

    const shouldInputComponentsBeEnabled = isEditable(state)
    inputCacs.forEach(({ component, compId }) => {
      updateComponentEnabledState(
        component,
        compId,
        shouldInputComponentsBeEnabled,
        logger,
        datasetId,
      )
    })

    linkedCacs.forEach(({ component, compId }) => {
      const { action } = component.connectionConfig.events.onClick
      const shouldBeEnabled = shouldEnableLinkedComponent[action]()
      updateComponentEnabledState(
        component,
        compId,
        shouldBeEnabled,
        logger,
        datasetId,
      )
    })
  }

const syncEnabledStateForComponentsNotDisabledByUser = (
  { getState, subscribe },
  componentAdapterContexts,
  logger,
  datasetId,
  recordStore,
) => {
  const shouldEnableLinkedComponent = {
    new: () => !isReadOnly(getState()),
    save: () => isEditable(getState()),
    revert: () => isEditable(getState()),
    remove: () => isEditable(getState()),
    next: () =>
      hasNextItem({
        state: getState(),
        recordStore,
      }),
    previous: () => hasPreviousItem({ state: getState() }),
    nextPage: () =>
      hasNextPage({
        state: getState(),
        recordStore,
      }),
    previousPage: () => hasPreviousPage({ state: getState() }),
    nextDynamicPage: () => selectNextDynamicPageUrl(getState()).hasUrl(),
    previousDynamicPage: () =>
      selectPreviousDynamicPageUrl(getState()).hasUrl(),
    loadMore: () =>
      canLoadMoreItems({
        state: getState(),
        recordStore,
      }),
  }

  const { inputCacs, linkedCacs } = getDisableableComponents(
    componentAdapterContexts,
    Object.keys(shouldEnableLinkedComponent),
  )

  const quantityOfDisableableComponents = inputCacs.length + linkedCacs.length

  const unsubscribe = quantityOfDisableableComponents
    ? subscribe(
        getSyncComponentsWithStateSubscriber({
          getState,
          inputCacs,
          linkedCacs,
          datasetId,
          logger,
          shouldEnableLinkedComponent,
        }),
      )
    : noop

  return unsubscribe
}

export default (
  store,
  componentAdapterContexts,
  logger,
  datasetId,
  recordStore,
) => {
  return syncEnabledStateForComponentsNotDisabledByUser(
    store,
    componentAdapterContexts,
    logger,
    datasetId,
    recordStore,
  )
}
