'use strict'

const SCOPE_TYPES = {
  PRIMARY: 'PRIMARY',
  DETAILS: 'DETAILS',
  OTHER: 'OTHER',
}

const getScopeType = ({
  datasetId,
  repeaterCompId,
  masterIds,
  connectionsGraph,
}) => {
  if (connectionsGraph[datasetId].includes(repeaterCompId)) {
    return SCOPE_TYPES.PRIMARY
  }

  if (
    masterIds.some(masterId =>
      connectionsGraph[masterId].includes(repeaterCompId),
    )
  ) {
    return SCOPE_TYPES.DETAILS
  }

  return SCOPE_TYPES.OTHER
}

export { SCOPE_TYPES, getScopeType }
