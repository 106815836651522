'use strict'

import baseAdapter from './baseAdapter'
import { AppError } from '../../logger'
import appContext from '../../viewer-app-module/DataBindingAppContext'

const adapter = ({ controllerFactory, controllerStore }) => {
  const { errorReporting } = appContext

  const itemReady = repeaterId => (scoped$w, itemData, index) => {
    const datasetScope = { repeaterId, itemId: itemData._id }
    const controller = controllerFactory.createScopedDetailsDataset({
      datasetScope,
      scoped$w: scoped$w.scoped,
    })
    controllerStore.setController(datasetScope, controller)
    controller.pageReady()
  }

  const itemRemoved = repeaterId => itemData => {
    const datasetScope = { repeaterId, itemId: itemData._id }
    controllerStore.removeController(datasetScope)
  }

  return {
    ...baseAdapter,

    bindToComponent({ component, compId }) {
      component.onItemReady(
        errorReporting(
          itemReady(compId),
          AppError.withMessage('Details repeater adapter onItemReady failed'),
        ),
      )
      component.onItemRemoved(
        errorReporting(
          itemRemoved(compId),
          AppError.withMessage('Details repeater adapter onItemRemoved failed'),
        ),
      )
    },
  }
}

export default adapter
