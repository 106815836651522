'use strict'
import {
  isTimeValid,
  isDateValid,
  mergeDateWithTime,
} from '../components/transformData/dateTimeUtils'
import { has, set, get } from 'lodash-es'

const mergeValueWithRecord = (value, fieldName, record) => {
  const currentValue = get(record, fieldName)

  const valueToSet =
    isTimeValid(currentValue) && isDateValid(value)
      ? mergeDateWithTime({
          time: currentValue,
          date: value,
        })
      : isDateValid(currentValue) && isTimeValid(value)
      ? mergeDateWithTime({
          time: value,
          date: currentValue,
        })
      : value

  set(record, fieldName, valueToSet)
}

const generateRecordFromDefaultComponentValues = componentAdapterContexts => {
  const inputComponentsProps = ['value', 'checked'] //todo: export to constant
  return componentAdapterContexts.reduce(
    (record, { component, connectionConfig: { properties } }) => {
      inputComponentsProps.forEach(propName => {
        if (has(properties, propName)) {
          mergeValueWithRecord(
            component[propName],
            properties[propName].fieldName,
            record,
          )
        }
      })
      return record
    },
    {},
  )
}

export default generateRecordFromDefaultComponentValues
