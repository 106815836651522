'use strict'
import { forEach, get } from 'lodash-es'
import baseAdapter from './baseAdapter'
import { selectCurrentRecord } from '../../dataset-controller/rootReducer'
import { AppError, VerboseMessage } from '../../logger'
import appContext from '../../viewer-app-module/DataBindingAppContext'

const isBoltRenderer = () =>
  appContext.platform.settings.env.renderer === 'bolt'

const initialView = { start: 0, end: 0 }

export default ({
  datasetApi,
  PresetVerboseMessage,
  getState,
  modeIsLivePreview,
}) => {
  const { logger, errorReporting } = appContext

  let currentView = initialView

  const setCurrentView = view => (currentView = view)
  const resetCurrentView = () => setCurrentView(initialView)

  const createOffsetBasedDataFetcher =
    ({ fetchRows, logGridValue, disableNavigation = false }) =>
    async (startRow, endRow) => {
      const { items, datasetSize } = await fetchRows(
        startRow,
        endRow - startRow,
      )
      logGridValue(items)

      return {
        pageRows: items,
        totalRowsCount: disableNavigation ? items.length : datasetSize.total,
      }
    }

  const createCursorBasedDataFetcher =
    ({ fetchRows, logGridValue, component: grid }) =>
    async ({ direction, limit }) => {
      let { start, end } = currentView

      if (direction === 'previous') {
        end = start
        start = start - limit
      } else {
        start = end
        end = end + limit
      }

      const { items, datasetSize } = await fetchRows(start, end - start)

      setCurrentView({ start, end })

      grid.nextEnabled = !!datasetSize.cursor || end < datasetSize.loaded
      grid.previousEnabled = start > 0

      logGridValue(items)

      return {
        pageRows: items,
        totalRowsCount: datasetSize.total || undefined,
      }
    }

  const logVerboseForBinding = grid => {
    const bindingDescription = {}

    grid.columns.forEach(({ label, dataPath, linkPath }) => {
      if (dataPath || linkPath) {
        bindingDescription[label] = Object.assign(
          dataPath ? { dataPath } : {},
          linkPath ? { linkPath } : {},
        )
      }
    })

    logger.log(
      new PresetVerboseMessage(VerboseMessage.types.COMPONENT.BOUND, {
        component: grid,
        description: bindingDescription,
      }),
    )
  }

  const logVerboseValueDescription = component => items => {
    const valueDescription = []
    const columns = component.columns

    forEach(items, item => {
      const value = {}
      forEach(columns, column => {
        value[column.label] = get(item, column.dataPath)
      })
      valueDescription.push(value)
    })

    logger.log(
      new PresetVerboseMessage(VerboseMessage.types.COMPONENT.FILLED, {
        component,
        description: valueDescription,
      }),
    )
  }

  return {
    ...baseAdapter,

    clearComponent({ component: grid }) {
      grid.rows = []
      grid.dataFetcher = undefined
    },

    bindToComponent({ component: grid }, actions) {
      // Synchronously set initial data for SEO rendering
      actions.getInitialData().chain(({ items }) => {
        grid.rows = items
      })

      const logGridValue = logVerboseValueDescription(grid)
      const record = selectCurrentRecord(getState())

      if (!modeIsLivePreview || record) {
        const isCursor = actions.isCursorPaging()
        const isBolt = isBoltRenderer()

        if (isBolt) {
          grid.dataFetcher = createOffsetBasedDataFetcher({
            fetchRows: actions.fetch,
            logGridValue,
            disableNavigation: isCursor,
          })
        } else {
          grid.dataFetcher = isCursor
            ? {
                type: 'directional',
                value: createCursorBasedDataFetcher({
                  fetchRows: actions.fetch,
                  logGridValue,
                  component: grid,
                }),
              }
            : {
                type: 'pages',
                value: createOffsetBasedDataFetcher({
                  fetchRows: actions.fetch,
                  logGridValue,
                }),
              }
        }
      }

      grid.onCellSelect(
        errorReporting(({ cellRowIndex }) => {
          datasetApi.setCurrentItemIndex(cellRowIndex)
        }, AppError.withMessage('Grid adapter onCellSelect failed')),
      )

      grid.onRowSelect(
        errorReporting(({ rowIndex }) => {
          datasetApi.setCurrentItemIndex(rowIndex)
        }, AppError.withMessage('Grid adapter onRowSelect failed')),
      )
      logVerboseForBinding(grid)
    },

    currentRecordModified({ component: grid }) {
      grid.refresh()
    },

    recordSetLoaded({ component: grid }) {
      resetCurrentView()
      grid.refresh()
    },

    currentViewChanged({ component: grid }) {
      resetCurrentView()
      grid.refresh()
    },
  }
}
