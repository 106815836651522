'use strict'

import { selectCurrentRecord } from '../../dataset-controller/rootReducer'
import baseAdapter from './baseAdapter'
import appContext from '../../viewer-app-module/DataBindingAppContext'
import { AppError, VerboseMessage } from '../../logger'

const adapter = ({
  getState,
  controllerFactory,
  controllerStore,
  PresetVerboseMessage,
  modeIsLivePreview,
}) => {
  const itemReadyPromises = []
  const { logger, errorReporting } = appContext

  const itemReady =
    (repeaterId, compNickname) => (scoped$w, itemData, index) => {
      const datasetScope = { repeaterId, itemId: itemData._id }
      const controller = controllerFactory.createScopedDataset({
        datasetScope,
        fixedItem: itemData,
        parentId: compNickname,
        scoped$w: scoped$w.scoped,
      })
      controllerStore.setController(datasetScope, controller)
      const pageReadyPromise = controller.pageReady()
      itemReadyPromises.push(pageReadyPromise)
    }

  const itemRemoved = repeaterId => itemData => {
    const datasetScope = { repeaterId, itemId: itemData._id }
    controllerStore.removeController(datasetScope)
  }

  const refreshView =
    () =>
    async ({ component }, actions) => {
      const { items } = await actions.fetchCurrentItems(getState())
      if (modeIsLivePreview && items.length === 0) return

      logger.log(
        new PresetVerboseMessage(VerboseMessage.types.COMPONENT.FILLED, {
          component,
          description: { data: items },
        }),
      )
      component.data = items
      await Promise.all(itemReadyPromises)
      itemReadyPromises.splice(0)
    }

  return {
    ...baseAdapter,

    clearComponent({ component }) {
      component.data = []
    },

    bindToComponent({ component, compId }) {
      const { id: compNickname } = component

      component.onItemReady(
        errorReporting(
          itemReady(compId, compNickname),
          AppError.withMessage('Repeater adapter onItemReady failed'),
        ),
      )

      component.onItemRemoved(
        errorReporting(
          itemRemoved(compId),
          AppError.withMessage('Repeater adapter onItemRemoved failed'),
        ),
      )

      logger.log(
        new PresetVerboseMessage(VerboseMessage.types.COMPONENT.BOUND, {
          component,
        }),
      )
    },

    currentRecordModified({ component }) {
      const updatedItem = selectCurrentRecord(getState())
      if (component.data && component.data.length > 0) {
        const existingItems = component.data

        const newItems = existingItems.map(existingItem =>
          existingItem._id === updatedItem._id ? updatedItem : existingItem,
        )
        component.data = newItems
      }
    },

    recordSetLoaded: refreshView(),
    currentViewChanged: refreshView(),
  }
}

export default adapter
