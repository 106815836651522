'use strict'

import baseAdapter from './baseAdapter'
import { AppError } from '../../logger'
import appContext from '../../viewer-app-module/DataBindingAppContext'
import { getUserFilterInitialData as getUserFilterInitialDataFromState } from '../../dataset-controller/rootReducer'
import {
  UserInputFilterType,
  RESET_ALL,
} from '@wix/wix-data-client-common/src/userInputFilters'
const { STATIC, MIN_MAX } = UserInputFilterType

export default ({ getState }) => {
  const { errorReporting, i18n } = appContext

  const getUserFilterInitialData = ({ fieldName, userInputFilterType }) =>
    getUserFilterInitialDataFromState(getState())?.find(
      userFilterInitialData =>
        userFilterInitialData.fieldName === fieldName &&
        userFilterInitialData.optionsType === userInputFilterType,
    )

  const componentHasPlaceholderField = component => 'placeholder' in component

  const getInitialComponentValue = ({ component, prop }) => {
    if (Array.isArray(component[prop])) {
      return []
    } else if (!componentHasPlaceholderField(component)) {
      return RESET_ALL
    }
  }

  const getComponentOptions = ({ component, prop, userFilterInitialData }) =>
    [
      !Array.isArray(component[prop]) && {
        value: RESET_ALL,
        label: i18n.t('USER_INPUT_FILTER_OPTION_RESET_ALL'),
      },
      ...userFilterInitialData.options.map(value => ({
        value: String(value),
        label: String(value),
      })),
    ].filter(Boolean)

  return {
    ...baseAdapter,

    bindToComponent({ component }, actions) {
      if (typeof component.onChange === 'function') {
        component.onChange(
          errorReporting(
            actions.refresh,
            AppError.withMessage('Filter input adapter onChange failed'),
          ),
        )
      }

      const [prop, { fieldName, optionsType: userInputFilterType }] =
        Object.entries(component.connectionConfig.properties)[0]

      if (userInputFilterType === STATIC) {
        component[prop] = null
        return
      }

      const userFilterInitialData = getUserFilterInitialData({
        fieldName,
        userInputFilterType,
      })

      if (userFilterInitialData) {
        if (userInputFilterType === MIN_MAX) {
          const { min, max } = userFilterInitialData
          component.value = [min, max]
          component.min = min
          component.max = max
          return
        }

        component.options = getComponentOptions({
          component,
          prop,
          userFilterInitialData,
        })

        component[prop] = getInitialComponentValue({ component, prop })
      }
    },
  }
}
