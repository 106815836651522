'use strict'
import { clamp } from 'lodash-es'
import baseAdapter from './baseAdapter'
import { getCurrentPageIndex, getPageSize } from '../../helpers/paginationUtils'
import { AppError, VerboseMessage } from '../../logger'
import appContext from '../../viewer-app-module/DataBindingAppContext'

const isBoltRenderer = () =>
  appContext.platform.settings.env.renderer === 'bolt'

const beginningOfPage = (pageNumber, pageSize) => pageSize * (pageNumber - 1)

export default ({ getState, PresetVerboseMessage }) => {
  const { logger, errorReporting } = appContext

  const refreshOffsetBased = ({ component: pagination }, actions) => {
    const currentPage = getCurrentPageIndex({ state: getState() })
    const totalPages = actions.getTotalPageCount(getState())

    pagination.currentPage = currentPage

    if (totalPages < 1) {
      pagination.disable()
    } else {
      pagination.enable()
      pagination.totalPages = totalPages
    }
  }

  const refreshCursorBased = ({ component: pagination }, actions) => {
    const currentPage = getCurrentPageIndex({ state: getState() })

    pagination.nextEnabled = actions.hasNextPage(getState())
    pagination.previousEnabled = currentPage > 1
  }

  const refreshView = (context, actions) => {
    const isCursor = actions.isCursorPaging()
    if (isBoltRenderer() && isCursor) {
      return
    }

    const fn = isCursor ? refreshCursorBased : refreshOffsetBased

    fn(context, actions)
  }

  return {
    ...baseAdapter,

    bindToComponent({ component }, actions) {
      const navigateToPage = ({ delta, index }) => {
        const currentPage = getCurrentPageIndex({ state: getState() })
        const requestedPage = delta ? currentPage + delta : index
        const pageSize = getPageSize({ state: getState() })
        const pageIndex = isCursor
          ? requestedPage
          : clamp(requestedPage, 1, actions.getTotalPageCount(getState()))

        actions.setCurrentIndex(beginningOfPage(pageIndex, pageSize))
      }

      const isBolt = isBoltRenderer()
      const isCursor = actions.isCursorPaging()

      if (isBolt && isCursor) {
        component.currentPage = 1
        component.disable()
      } else if (isCursor) {
        component.navigationType = 'arrowsOnly'

        component.onNextClicked(
          errorReporting(
            () => navigateToPage({ delta: 1 }),
            AppError.withMessage('Pagination adapter onNextClicked failed'),
          ),
        )
        component.onPreviousClicked(
          errorReporting(
            () => navigateToPage({ delta: -1 }),
            AppError.withMessage('Pagination adapter onPreviousClicked failed'),
          ),
        )
      } else {
        component.onChange(
          errorReporting(
            event => navigateToPage({ index: event.target.currentPage }),
            AppError.withMessage('Pagination adapter onChange failed'),
          ),
        )
      }

      logger.log(
        new PresetVerboseMessage(VerboseMessage.types.COMPONENT.BOUND, {
          component,
        }),
      )
    },

    recordSetLoaded: refreshView,

    currentViewChanged: refreshView,
  }
}
