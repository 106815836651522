'use strict'

export default () => {
  let connectedComponents //TODO: cannot be inited as an empty array because of userInput filters

  const findConnectedComponents = (roles, $w) => {
    const result = []
    roles.forEach(role => {
      const components = $w('@' + role)
      components &&
        components.forEach(
          component =>
            component &&
            result.push({ role, component, compId: component.uniqueId }),
        )
    })

    return result
  }

  const setConnectedComponents = components =>
    (connectedComponents = components)

  const getConnectedComponents = () => connectedComponents

  return {
    findConnectedComponents,
    setConnectedComponents,
    getConnectedComponents,
  }
}
